//
// Pages
//

body[data-layout-mode="dark"] {
    // Chat
    .chat-list {
        li {
            a{
                border-color: $gray-dark-300;
            }
            .user-img{
                .user-status{
                    border-color: $gray-dark-200;
                }
            }
        }
    }
    .chat-input-section{
        border-top: 1px solid $gray-dark-300;
    }
    .chat-conversation{
        .right{
            .conversation-list{
                .ctext-wrap{
                    .ctext-wrap-content{
                        background-color: $gray-dark-300;
                        &::before{
                            border-top-color: $gray-dark-300;
                            border-left-color: $gray-dark-300;
                        }
                    }
                }
            }
        }
    }
    .chat-input{
        background-color: lighten($gray-dark-200,2.5%) !important;
        border: 1px solid $gray-dark-300 !important;
    }


    // Email
    .mail-list{
        a{
            color: $gray-dark-400;
        }
    }
    .message-list{
        li{
            a{
                color: $gray-dark-400;
            }
            &.unread, &:hover{
                background-color: lighten($gray-dark-200,2.5%);
            }
        }
        .checkbox-wrapper-mail{
            box-shadow: inset 0 0 0 1px $gray-dark-400;
            label:before{
                color: $gray-dark-500;
            }
        }
    }

    // Timeline

    .timeline{
        &:before {
            background-color: darken($gray-dark-300,2%);
        }

        .timeline-icon {
            &:before {
                background-color: darken($gray-dark-300,2%);
            }
        }
    }

    // Pricing

    .pricing-nav-tabs {
        background-color: lighten($gray-dark-200,2.5%);
    }

}
