// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  padding: 1.25rem 0;
  border-radius: 0;
  border: none;
  border-top: 1px solid #e9ebec;
  border-bottom: 1px solid #e9ebec;
  min-height: 170px;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

.card-height-100 {
  height: calc(100% - #{$grid-gutter-width});
}

.card-body {
  padding: 0 0.5rem;
  border-left: 1px solid #e9ebec;
  border-right: 1px solid #e9ebec;
}

.p-title {  
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}